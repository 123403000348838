<template>
  <div>
    <!-- 复选框操作  @selection-change="checkboxchange" -->
    <el-table ref="mytable" :data="list">
      <el-table-column label="账号名" prop="userName"></el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="item">
          <el-button @click="clickEdit(item.row)">编辑</el-button>
          <el-button type="danger" @click="clickRemove(item.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="mt30">
      <el-pagination
        @size-change="sizechange"
        @current-change="pagechange"
        :current-page="curpage"
        :page-sizes="changepagesizes"
        :page-size="curpagesize"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>

    <!-- 修改模态框 -->
    <el-dialog
      :title="updatUserName + '账号修改用户信息'"
      :visible.sync="dialogFormVisible"
      @close="closeDialog"
    >
      <p class="mb20">用户原密码</p>
      <el-input
        v-model="updateUser.password"
        autocomplete="off"
        show-password
      ></el-input>
      <p class="mb20 mt30">用户新密码</p>
      <el-input
        v-model="updateUser.newPassword"
        autocomplete="off"
        show-password
      ></el-input>
      <p class="mb20 mt30">用户新密码</p>
      <el-input
        v-model="updateUser.newPasswordAgain"
        autocomplete="off"
        show-password
      ></el-input>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="clickEditOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUserList, updatePassword, deleteUser } from "@/apis/users";

export default {
  data() {
    return {
      list: [], //数据数组

      total: 0, //总条数
      changepagesizes: [5, 10, 15, 20, 25], //可以切换的条数数组
      curpage: 1, //当前页数
      curpagesize: 10, //当前选中的条数

      dialogFormVisible: false, //是否显示编辑框
      //*修改的用户信息
      updateUser: {
        id: "",
        userName: "",
        password: "",
        newPassword: "",
        newPasswordAgain: "",
      },
      //*删除的用户信息
      deleteUserId: "",
      updatUserName:''
    };
  },
  created() {
    //获取的是时区时间
    // console.log(date.getHours(), date.getMinutes(), date.getSeconds())
    //获取的是国际UTC时间
    // console.log(date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())

    this.reqtabledata();
  },
  methods: {
    // *删除用户
    clickRemove(row) {
      // *删除用户二次确认框
      this.$confirm("确定删除该该用户吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteUser({ id: row.id }).then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.reqtabledata();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      });
    },

    //*请求表格数据
    reqtabledata() {
      //发送请求，拿到数据
      getUserList({ page: this.curpage, size: this.curpagesize }).then(
        (res) => {
          if (res.data.code == 0) {
            this.list = res.data.data.users;
            this.total = res.data.data.count;
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        }
      );
    },

    //点击切换每页显示的条数
    sizechange(newsize) {
      this.curpagesize = newsize; //先改变新的显示条数
      this.reqtabledata(); //请求新数据！
    },
    //点击跳转页数
    pagechange(newpage) {
      this.curpage = newpage; //先把当前页数切换到新的页数
      this.reqtabledata(); //请求新页的数据
    },
    //点击编辑，弹出修改框
    clickEdit(row) {
      this.updatUserName  = row.userName
      this.dialogFormVisible = true;
      //拿到数据回填
      this.updateUser.id = row.id;
      this.updateUser.userName = row.userName;
    },
    // 点击确定编辑
    clickEditOk() {
      if (this.updateUser.password && this.updateUser.newPassword) {
        if (this.updateUser.newPassword == this.updateUser.newPasswordAgain) {
          updatePassword(this.updateUser).then((res) => {
            if (res.data.code == 0) {
              // *清空数据
              this.updateUser.password = "";
              this.updateUser.newPassword = "";
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              this.dialogFormVisible = false;
              this.reqtabledata();
            } else {
              this.$message({
                type: "error",
                message: res.data.msg,
              });
            }
          });
        } else {
          this.$message({
            message: "两次密码不同",
            type: "error",
          });
        }
      } else {
        this.$message({
          type: "error",
          message: "密码不能为空!",
        });
        return;
      }
    },
    // *关闭
    closeDialog() {
      this.updateUser = {
        id: "",
        userName: "",
        password: "",
        newPassword: "",
        newPasswordAgain: "",
      };
    },
  },
};
</script>

<style lang="less" scoped></style>
